import React, { useContext } from "react"
import useScrollPosition from "~utilities/useScrollPosition"
import useWindowDimensions from "~utilities/getDimension"
import { scrollTo } from "~utilities/scroll"
import { GlobalState } from "~utilities/GlobalContextProvider"

const ScrollBehavior = () => {
  const { state, dispatch } = useContext(GlobalState)
  const { height: screenHeight } = useWindowDimensions()

  const setData = (data) => {
    if (data !== state.isFirstScreen) {
      dispatch({ type: "SET_IS_FIRST_SCREEN", isFirstScreen: data })
    }
  }

  let scorllingInProgress = false
  useScrollPosition(({ prevPos, currPos, dir }, e) => {
    // prevent scroll behavior when animation in progress.
    if (!scorllingInProgress) {
      // if in the first screen currently:
      if (currPos.y > screenHeight * -1) {
        if (dir === "down") {
          scorllingInProgress = true
          scrollTo(screenHeight, () => {
            scorllingInProgress = false
            setData(false)
          })
        }
        if (dir === "up") {
          scorllingInProgress = true
          setData(true)
          scrollTo(0, () => (scorllingInProgress = false))
        }
      } else {
        setData(false)
      }
    } else {
      e.preventDefault()
    }
  })

  return <></>
}

export default ScrollBehavior
