import React, { useContext } from "react"
import styled from "styled-components"
import Icon from "~assets/icon"
import { scrollTo, scrollToEleById } from "~utilities/scroll"
import Burger from "./Burger"
import Menu from "./Menu"
import { GlobalState } from "~utilities/GlobalContextProvider"

const MobileNav = ({ className, items }) => {
  const lastItem = items[items.length - 1]

  const { state } = useContext(GlobalState)

  const openMenu = state.menu === "openMenu"

  return (
    <div className={className}>
      <NavContainer
        className={`${!state.isFirstScreen && "active"} ${openMenu && "open"}`}
      >
        <Wrapper>
          <Burger />
          <Logo icon="logo" width="85" onClick={() => scrollTo(0)} />
        </Wrapper>

        <CTA
          className={openMenu && "hide"}
          onClick={() => scrollToEleById(lastItem.anchor, -70)}
        >
          Let's Talk
        </CTA>
      </NavContainer>

      <Menu open={openMenu} items={items} />
    </div>
  )
}

export default MobileNav

const NavContainer = styled.nav`
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  color: var(--color-white);
  z-index: 100;
  box-shadow: 0 4px 12px 0 rgba(26, 30, 55, 0.2),
    inset 0 -1px 0 0 rgba(79, 80, 89, 0.5);
  background-color: rgba(67, 69, 88, 0.98);
  opacity: 0;
  transform: translateY(-80px);
  transition: opacity 0.7s, transform 0.7s, background-color 0.7s,
    box-shadow 0.7s;
  will-change: opacity, transform, background-color, box-shadow;

  &.active {
    opacity: 1;
    transform: translateY(0);
  }

  &.open {
    background-color: #292939;
    box-shadow: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Logo = styled(Icon)`
  cursor: pointer;
  position: relative;
  top: 4px;
  margin-left: 20px;
`

const CTA = styled.div`
  position: relative;
  font-family: "SF Pro", Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.7s, transform 0.7s;
  will-change: opacity, transform;

  &::before {
    content: "";
    width: 8px;
    height: 2px;
    position: absolute;
    bottom: -4px;
    left: 0;
    border-radius: 2px;
    background-color: var(--color-red);
  }

  &.hide {
    opacity: 0;
    transform: translateX(15px);
  }
`
