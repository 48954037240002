import React, { useContext } from "react"
import styled from "styled-components"
import { scrollToEleById } from "~utilities/scroll"
import { Link } from "gatsby"
import { GlobalState } from "~utilities/GlobalContextProvider"

const Menu = ({ items }) => {
  const { state, dispatch } = useContext(GlobalState)

  return (
    <Wrapper className={`${state.menu === "openMenu" && "active"}`}>
      <NavItems>
        {items.map((item, index) => {
          const offset = index === 0 ? 0 : -70
          return (
            <Item
              selected={item.selected}
              key={index}
              onClick={() => {
                dispatch({ type: "RESET_BURGER" })
                scrollToEleById(item.anchor, offset)
              }}
            >
              <span>0{index + 1}</span>
              <p>{item.title}</p>
            </Item>
          )
        })}
      </NavItems>

      <Footer>
        <div>
          <Link to="/docs/privacy">Privacy</Link>
          <Link to="/docs/terms ">Terms</Link>
        </div>
        <p>
          Copyrioght © {new Date().getFullYear()} Moozy Studio. Serving clients
          worldwide.
        </p>
      </Footer>
    </Wrapper>
  )
}

export default Menu

const Wrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 70px);
  position: fixed;
  top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 28px;
  background-color: #292939;
  z-index: 100;
  opacity: 0;
  transform: translateX(-20px);
  pointer-events: none;
  transition: opacity 0.7s, transform 0.7s;
  will-change: opacity, transform;

  &.active {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
  }
`

const NavItems = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  top: -35px;
`

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  span {
    display: block;
    position: relative;
    top: 2px;
    font-family: "JetBrains Mono", sans-serif;
    font-size: 9px;
    margin: 0 5px 0 0;
    user-select: none;

    ${(props) =>
      props.selected &&
      `
        &::before {
      content: "";
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: -6px;
      left: 0;
      border-radius: 2px;
      background-color: var(--color-red);
    }`}
  }

  p {
    font-size: 18px;
    font-family: "JetBrains Mono", sans-serif;
    font-weight: bold;
    color: var(--color-white);
    margin: 0;
    user-select: none;
  }
`

const Footer = styled.div`
  position: absolute;
  bottom: 20px;

  a {
    font-family: "SF Pro", Arial, Helvetica, sans-serif;
    font-size: 11px;
    color: var(--color-white);
    opacity: 0.5;
    text-decoration: none;
    margin-right: 20px;
  }

  p {
    font-family: "SF Pro", Arial, Helvetica, sans-serif;
    font-size: 9px;
    color: var(--color-white);
    margin: 10px 0 0;
  }
`
