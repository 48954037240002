import React from "react"
import Icon from "~assets/icon"
import Layout from "~components/Layout"
import { Header, Section, Color } from "~components/styleguide-components"
import Button from "~components/Shared/Button"

const StyleguidePage = () => (
  <Layout title="StyleGuide">
    <Header>
      <Icon icon="logo-colored" width="120" />
      <h5>StyleGuide</h5>
    </Header>
    <div style={{ backgroundColor: "#fafcfd" }}>
      <Section title="Colors">
        <Color color="#DB2337" name="--color-red" />
        <Color color="#1573E5" name="--color-blue" />
        <Color color="#DBDEE3" name="--color-button" />
        <Color color="#11121A" name="--color-button-text" />
        <Color color="#93949A" name="--color-text" />
        <Color color="#333646" name="--color-bg-1" />
        <Color color="#252735" name="--color-bg-2" />
      </Section>
      <Section title="Typography">
        <h1>This is H1 text</h1>
        <h2>This is H2 text</h2>
        <h3>This is H3 text</h3>
        <h4>This is H4 text</h4>
        <h5>This is H5 text</h5>
        <p>This is paragraph textThis is paragraph textThis is paragraph textThis is paragraph textThis is paragraph text</p>
      </Section>
      <Section title="Buttons">
        <Button theme="red" icon="comment" content="Contact Us" />
        <Button theme="white" content="Contact Us" />
      </Section>
    </div>
  </Layout>
)

export default StyleguidePage
