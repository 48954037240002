import React from "react"
import styled from "styled-components"

export const Header = styled.div`
  display: flex;
  padding: 0;
  width: 85%;
  margin: 20px auto;
  flex-direction: column;
  align-items: baseline;

  h5 {
    margin-top: 12px;
    font-size: 20px;
    letter-spacing: 1px;
    color: #777;
  }
`

export const Section = ({ title, children }) => {
  const Wrapper = styled.section`
    max-width: 85%;
    margin: 0 auto;
    padding: 40px 0;

    & > h4 {
      width: 100%;
      border-bottom: 1px solid var(--color-button);
      color: var(--color-text);
      font-size: 24px;
    }
  `

  let Items;
  if (title === "Typography") {
    Items = styled.div`
      display: block;
    `
  } else {
    Items = styled.div`
      display: flex;
      flex-wrap: wrap;
    `
  }

  return (
    <Wrapper>
      <h4>{title}</h4>
      <Items>{children}</Items>
    </Wrapper>
  )
}

export const Color = ({ color, name }) => {
  const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 33%;
    margin-bottom: 1.45rem;

    h5 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 3px;
      color: var(--color-button-text);
    }

    p {
      font-size: 14px;
      color: var(--color-text);
    }
  `
  const Circle = styled.div`
    width: 75px;
    height: 75px;
    margin-right: 40px;
    border-radius: 50px;
    background-color: ${props => props.color};
  `

  return (
    <Wrapper>
      <Circle color={color} />
      <div>
        <h5>{name}</h5>
        <p>{color}</p>
      </div>
    </Wrapper>
  )
}
