import React from "react"
import DesktopNav from "./Desktop"
import MobileNav from "./Mobile"

const items = [
  { title: "Services", anchor: "anchor-services" },
  { title: "Why Moozy", anchor: "anchor-why" },
  { title: "Process", anchor: "anchor-processes" },
  { title: "Partners", anchor: "anchor-partners" },
  { title: "Get Start", anchor: "anchor-start", selected: true },
]

const Nav = () => (
  <>
    <DesktopNav items={items} className="render-over-tablet" />
    <MobileNav items={items} className="render-below-tablet" />
  </>
)

export default Nav
