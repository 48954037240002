const isBrowser = typeof window !== `undefined`

export const scrollTo = (offset, callback) => {
  if (isBrowser) {
    const fixedOffset = offset.toFixed(),
      onScroll = function () {
        if (window.pageYOffset.toFixed() === fixedOffset) {
          window.removeEventListener("scroll", onScroll)
          if (callback) callback()
        }
      }

    window.addEventListener("scroll", onScroll)
    onScroll()
    window.scrollTo({
      top: offset,
      behavior: "smooth",
    })
  }
}

export const scrollToEleById = (id, offset = 0) => {
  if (isBrowser) {
    const element = document.getElementById(id)
    if (element) {
      const topDistance =
        element.offsetTop - element.scrollTop + element.clientTop + offset
      scrollTo(topDistance)
    }
  }
}
