import React, { useContext } from "react"
import styled from "styled-components"
import { GlobalState } from "~utilities/GlobalContextProvider"

const Burger = () => {
  const { state, dispatch } = useContext(GlobalState)

  const handleClick = () => {
    switch (state.menu) {
      case "initState":
        dispatch({ type: "OPEN_MENU" })
        break
      default:
        dispatch({ type: "RESET_BURGER" })
    }
  }

  return (
    <StyledBurger onClick={handleClick} name={state.menu}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

export default Burger

const StyledBurger = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 2px;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: ${({ name }) => {
      if (name === "openMenu") return "21px"
      return "20px"
    }};
    height: ${({ name }) => {
      if (name === "openMenu") return "3px"
      return "2px"
    }};
    background: #effffa;
    border-radius: 5px;
    transition: opacity 0.3s linear, transform 0.3s linear, height 0.3s linear;
    will-change: opacity, transform, height;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ name }) => {
        if (name === "openMenu") return "rotate(45deg)"
        if (name === "expandServices") return "translateY(7px) rotate(-45deg)"
        return "rotate(0)"
      }};
      ${({ name }) => name === "expandServices" && "width: 12px;"}
    }

    :nth-child(2) {
      opacity: ${({ name }) => {
        if (name === "openMenu") return "0"
        return "1"
      }};
      transform: ${({ name }) => {
        if (name === "openMenu") return "translateX(-5px)"
        return "translateX(0)"
      }};
    }

    :nth-child(3) {
      transform: ${({ name }) => {
        if (name === "openMenu") return "rotate(-45deg)"
        if (name === "expandServices") return "translateY(-7px) rotate(45deg)"
        return "rotate(0)"
      }};
      ${({ name }) => name === "expandServices" && "width: 12px;"}
    }
  }
`
