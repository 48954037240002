import React from "react"
import styled from "styled-components"
import Icon from "~assets/icon"

const StyledButton = styled.div`
  box-sizing: border-box;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "SF Pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  padding: 12px 17px;
  background-color: ${(props) => {
    switch (props.theme) {
      case "red":
        return "var(--color-red)"
      case "blue":
        return "var(--color-blue)"
      default:
        return "var(--color-button)"
    }
  }};
  color: ${(props) => {
    switch (props.theme) {
      case "red":
        return "var(--color-white)"
      case "blue":
        return "var(--color-white)"
      default:
        return "var(--color-button-text)"
    }
  }};
  ${(props) => props.disable && "filter: grayscale(1);"}
`

const Button = ({
  theme = "white",
  content = "Content",
  icon = "arrow",
  ...props
}) => (
  <StyledButton theme={theme} {...props}>
    {content}
    <Icon icon={icon} width="24" />
  </StyledButton>
)
export default Button
