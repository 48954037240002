import React, { useContext } from "react"
import styled from "styled-components"
import Icon from "~assets/icon"
import { scrollTo, scrollToEleById } from "~utilities/scroll"
import { GlobalState } from "~utilities/GlobalContextProvider"

const DesktopNav = ({ className, items }) => {
  const { state } = useContext(GlobalState)

  return (
    <NavContainer className={`${className} ${!state.isFirstScreen && "active"}`}>
      <Logo icon="logo" width="85" onClick={() => scrollTo(0)} />
      <NavItems>
        {items.map((item, index) => {
          const offset = index === 0 ? 0 : -70
          return (
            <Item
              selected={item.selected}
              key={index}
              onClick={() => scrollToEleById(item.anchor, offset)}
            >
              <span>0{index + 1}</span>
              <p>{item.title}</p>
            </Item>
          )
        })}
      </NavItems>
    </NavContainer>
  )
}

export default DesktopNav

const NavContainer = styled.nav`
  width: 90%;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  color: var(--color-white);
  z-index: 100;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  background-color: rgba(37, 39, 53, 0.96);

  opacity: 0;
  transform: translateY(-80px);
  transition: opacity 0.7s, transform 0.7s;
  will-change: opacity, transform;

  &.active {
    opacity: 1;
    transform: translateY(0);
  }
`

const Logo = styled(Icon)`
  cursor: pointer;
  position: relative;
  top: 2px;
`

const NavItems = styled.div`
  display: flex;
`

const Item = styled.div`
  margin-left: 32px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  span {
    display: block;
    position: relative;
    top: 2px;
    font-family: "JetBrains Mono", sans-serif;
    font-size: 9px;
    margin: 0 5px 0 0;
    user-select: none;

    ${(props) =>
      props.selected &&
      `
        &::before {
      content: "";
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: -6px;
      left: 0;
      border-radius: 2px;
      background-color: var(--color-red);
    }`}
  }

  p {
    font-size: 16px;
    font-family: "JetBrains Mono", sans-serif;
    font-weight: bold;
    color: var(--color-white);
    margin: 0;
    user-select: none;
  }
`
