import styled from "styled-components"
import { mobile } from "~utilities/mediaQuery"

export const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding-bottom: 50px;
  overflow: hidden;

  ${mobile} {
    padding-left: 24px;
    padding-right: 24px;
  }
`

export const RelativeDiv = styled.div`
  position: relative;
`

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
