import React from "react"
import PropTypes from "prop-types"
import { GlobalStyle } from "./GlobalStyle"
import SEO from "./seo"
import Nav from "./Nav/index"
import Footer from "./Footer"
import ScrollBehavior from "./ScrollBehavior"

const Layout = (props) => {
  return (
    <>
      <SEO {...props} />
      <GlobalStyle />
      <Nav />
      {props.children}
      <Footer />
      <ScrollBehavior />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
