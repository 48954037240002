import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Container } from "~components/Shared/Mixins"
import { mobile } from "~utilities/mediaQuery"

const Footer = () => (
  <FooterContainer>
    <Contact>
      <a href="mailto:hello@moozy.io">hello@moozy.io</a>
    </Contact>
    <div>
      <Links>
        <Link to="/docs/privacy">Privacy</Link>
        <Link to="/docs/terms ">Terms</Link>
      </Links>
      © {new Date().getFullYear()} Moozy Studio. Serving clients worldwide.
    </div>
  </FooterContainer>
)

export default Footer

const FooterContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: 75px;
  color: var(--color-white);

  ${mobile} {
    padding: 36px 10%;
    flex-direction: column;
  }

  a {
    font-size: 12px;
    font-family: "SF Pro", Arial, Helvetica, sans-serif;
    text-decoration: none;
    color: var(--color-white);
  }
`

const Contact = styled.div`
  a {
    font-weight: bold;
  }
`

const Links = styled.div`
  text-align: right;
  margin-bottom: 15px;

  ${mobile} {
    text-align: left;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  a {
    margin-left: 15px;
    opacity: 0.7;

    ${mobile} {
      margin: 0 15px 0 0;
    }
  }
`
