import { useRef, useLayoutEffect } from "react"

const isBrowser = typeof window !== `undefined`

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

export default function useScrollPosition(
  effect,
  deps,
  element,
  useWindow,
  wait
) {
  const position = useRef(getScrollPosition({ useWindow }))

  const callBack = (e) => {
    const currPos = getScrollPosition({ element, useWindow })
    const dir = position.current.y > currPos.y ? "down" : "up"
    effect({ prevPos: position.current, currPos, dir }, e)
    position.current = currPos
  }

  useLayoutEffect(() => {
    const handleScroll = (e) => callBack(e)

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
